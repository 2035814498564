import * as React from "react"
import * as styles from "../styles/home.module.scss"
import Template from "../components/ui/Template"
import { Button } from "@material-ui/core"
import { Helmet } from "react-helmet"
import { Block } from "@material-ui/icons"
import { Link } from "gatsby"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return <Template currentPage="group" lightnessTheme="dark">
      <Helmet>
        <title>Daya Selaras Group - Daya Cipta Kemasindo</title>
      </Helmet>
      <div className={styles.jumbotron} style={{backgroundImage: "url('/static/images/photos/dus.jpg')"}}>
        <div className={styles.fence}>
          <div className={`${styles.mission} ${styles.missionWithResponsiveImage}`}>
            <div className={`${styles.responsiveImage} ${styles.responsiveImageLongText}`} style={{alignItems: "center"}}>
              <video autoplay="true" loop controls style={{ width: "100%", aspectRatio: "16/9"}}>
                <source src="/static/videos/PL_campaign_Eng.mp4" />
              </video>
            </div>
            <div className={styles.missionItemLongText}>
              <div className={styles.title}>
                DAUR ULANG SELARAS
              </div>
              <div className={styles.info}>
                A waste paper recycling management company that recovers and transforms waste paper into value by promoting a circular value chain. 
              </div>
              <Link to="/dus#services" className={styles.buttonLink}><Button className={styles.button} color="primary" variant="contained">READ MORE</Button></Link>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.jumbotron} ${styles.jumbotron__light}`} style={{backgroundImage: "none", backgroundColor: "#c7d6cd"}} id="services">
        <div className={`${styles.fence} ${styles.fence__row}`}>
          <div className={styles.cksSlide}>
            <div className={styles.title}>
              OUR <br />SERVICES
            </div>
          </div>
          <img src="/static/images/dus-detail.png" style={{width: "100%", maxWidth: "900px"}} />
        </div>
      </div>
    </Template>
  }
}

export default IndexPage
